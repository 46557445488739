.link {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  margin-left: 20px;
  background: linear-gradient(90deg, #1EDA8E 0%, #2FADEE 100%);
}

.link:hover {
  background: linear-gradient(90deg, #2FADEE 0%, #1EDA8E 100%);
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.25);
}

@media (min-width: 1024px) {
  .link {
    margin-left: 100px;
  }
}