.navbar__link:hover {
  color: #1EDA8E;
  font-weight: 600;
}

.navbar__logo {
  max-width: 7rem;
  left: 0;
  top: 0;
}

@media (min-width: 1024px) {
  .navbar__logo {
    max-width: 8rem;
  }
}

@media (min-width: 1400px) {
  .navbar__link {
    font-size: 1rem;
  }

  .navbar__logo {
    max-width: 14rem;
    padding-left: 50px;
  }
}

@media (min-width: 2000px) {
  .navbar {
    padding-top: 50px;
  }

  .navbar__link {
    font-size: 1.75rem;
  }
  
  .navbar__logo {
    max-width: 100%;
  }
}