@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import './components/Header/header.css';
@import './components/About/about.css';
@import './components/Products/components/SectionProducts/sectionProducts.css';
@import './components/Products/components/product.css';
@import './components/Parceiros/parceiros.css';
@import './components/Footer/footer.css';
@import './components/SectionVideo/sectionVideo.css';

body {
  color: #454545;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  background: url('../public/assets/bgHome.jpg') no-repeat top center;
  background-size: 100vw;
}
