.about span {
  color: #1EDA8E;
  font-size: 1.125rem;
}

.about p {
  font-size: 1.375rem;
  max-width: 40ch;
}

.about a {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  background: linear-gradient(90deg, #1EDA8E 0%, #2FADEE 100%);
}

.about a:hover {
  background: linear-gradient(90deg, #2FADEE 0%, #1EDA8E 100%);
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.25);
}

@media (max-width: 768px) {
  .about span {
    font-size: 0.6rem;
  }
  
  .about h1 {
    font-size: 1.5rem;
    max-width: 20rem;
  }
  
  .about p {
    font-size: 0.6rem;
    max-width: 30ch;
  }

  .about a {
    font-size: 0.6rem
  }

  svg {
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 992px) {
  .about h1 {
    max-width: 30rem;
  }
  .about p {
    max-width: 40ch;
  }
}

@media (min-width: 1024px) {
  .about {
    margin-top: 96px;
    padding: 0 100px !important; 
  }

  .about h1 {
    font-size: 3rem;
  }

  .about p {
    font-size: 1rem;
  }
}

@media (min-width: 1400px) {
  .about {
    margin-top: 2rem;
    padding: 100px !important; 
  }
}

@media (min-width: 2000px) {
  .about {
    margin-top: 14rem;
  }

  .about span {
    font-size: 1.75rem;
  }
  
  .about h1 {
    font-size: 5rem;
    max-width: 70rem;
  }
  
  .about p {
    font-size: 1.75rem;
    max-width: 50ch;
  }

  .about a {
    font-size: 1.75rem
  }

  svg {
    width: 28px;
    height: 28px;
  }
}