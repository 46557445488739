.sectionVideo {
  background: url("../../../public/assets/bgSectionVideo.png") no-repeat;
  background-size: cover;
}

.sectionVideo__video {
  width: 100%;
}

.sectionVideo__text {
  max-width: 55ch;
}

.sectionVideo__text span {
  font-size: 1.125rem;
}

.sectionVideo__text h2 {
  font-size: 24px;
  font-weight: 600;
}

.sectionVideo__text p {
  margin-top: 16px;
  font-size: 16px;
  font-weight: normal;
}

.sectionVideo__text small {
  font-style: italic;
  color: #fdfdfd;
  font-size: 14px;
}

.sectionVideo__text span,
.sectionVideo__text h2,
.sectionVideo__text p {
  color: #fdfdfd;
}

@media (min-width: 768px) {
  .sectionVideo__video {
    width: 75%;
  }
}

@media (min-width: 1024px) {
  .sectionVideo {
    margin-top: 100px !important;
    padding: 100px !important;
  }

  .sectionVideo__video {
    width: 50%;
  }

  .sectionVideo__text {
    max-width: 40ch;
    margin: 0;
  }

  .sectionVideo__text h2 {
    font-size: 1.875rem;
  }

  .sectionVideo__text p {
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  .sectionVideo__text {
    max-width: 50ch;
    margin-right: 7rem;
  }

  .sectionVideo__video {
    width: 36.25rem;
  }
}

@media (min-width: 2000px) {
  .sectionVideo {
    padding: 0;
    justify-content: center !important;
  }

  .sectionVideo__video {
    width: 46rem;
  }

  .sectionVideo__text {
    max-width: 70ch;
    margin-left: 100px !important;
  }

  .sectionVideo__text span {
    font-size: 1.75rem;
  }

  .sectionVideo__text h2 {
    font-size: 3rem;
  }

  .sectionVideo__text p {
    font-size: 1.75rem;
  }

  .sectionVideo__text small {
    font-size: 1.30rem;
  }
}
