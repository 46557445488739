.container__parceiros {
  background: url("../../../public/assets/bgNossosParceiros.png") no-repeat;
  background-size: cover;
}

.parceiros-card {
  display: flex;
  flex-wrap: wrap;
  width: 16.125rem;
  justify-content: center;
  padding: 36px !important;
}

.parceiros-card img {
  width: 7rem;
}

.parceiros-card p {
  text-align: center;
  font-size: 0.90rem;
  max-width: 20ch;
}

.parceiros-card a {
  color: #fff;
  font-weight: 500;
  font-size: 0.90rem;
  text-decoration: none;
  background: linear-gradient(90deg, #1eda8e 0%, #2fadee 100%);
}

.parceiros-card a:hover {
  background: linear-gradient(90deg, #2fadee 0%, #1eda8e 100%);
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.25);
}

.parceiros__conteudo span {
  color: #1eda8e;
  font-size: 1.125rem;
}

.parceiros__conteudo-titulo {
  max-width: 60ch;
}

@media (min-width: 1024px) {
  .container__parceiros {
    padding: 100px !important; 
  }
}

@media (min-width: 1400px) {
  .parceiros__conteudo h2 {
    max-width: 35ch;
  }
}

@media (min-width: 2000px) {
  .parceiros__conteudo span {
    font-size: 1.75rem;
  }

  .parceiros__conteudo h2 {
    font-size: 3rem;
  }

  .parceiros__conteudo p {
    font-size: 1.75rem;
    max-width: 60ch;
  }

  .parceiros-card {
    width: 24rem;
  }

  .parceiros-card img {
    width: auto;
  }

  .parceiros-card p {
    font-size: 1.5rem;
  }

  .parceiros-card a {
    font-size: 1.5rem;
  }
}
